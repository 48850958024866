import React from "react"
import "./info.scss"

const Info = () => {
  return (
    <div className="wrapper-info">
      <div className="info-container-one">
        <div className="section">
          <h2 className="title">We vetted the hosts.</h2>
          <p className="description">
            You won’t meet hosts that we haven’t met ourselves. Face to face. We
            have a pic to prove it.
          </p>
        </div>
        <div className="section">
          <h2 className="title">We vetted the facilities.</h2>
          <p className="description">
            So you don’t end up someplace you don’t want to end up.
          </p>
        </div>
        <div className="section">
          <h2 className="title">We basically pack your bag.</h2>
          <p className="description">
            Every trip comes with detailed directions and recommendations - what
            to bring, where to go, what to wear, what to avoid - it’s all there
            if you want it.
          </p>
        </div>
      </div>
      <div className="info-container-two">
        <div className="section">
          <h2 className="title">Be someone different, somewhere different.</h2>
          <p className="description">
            Our extraordinary hosts will provide you with a different locale, a
            different lifestyle, and a different experience for a different
            (read: brave, independent, present) you. Because the days are
            starting to melt together and you can’t feel the difference between
            Sunday and Wednesday anymore. Because having your food delivered to
            your door just reinforces that gnawing feeling that you’re not
            participating in your own life. This is for you.{" "}
          </p>
          <p className="description">
            This isn't a vacation package. There will be no upselling to dread
            because we’re not selling anything.{" "}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Info
